<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-breadcrumbs class="pl-0" :items="breads" large></v-breadcrumbs>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-btn
          v-if="proceso.id_seguimiento_proceso == 11"
          @click="showConfirmation = true"
          :disabled="disableBtn"
          color="secondary white--text"
          block
        >
          Publicar
        </v-btn>
        <v-btn
          class="my-2 ml-4"
          @click="abrirModalSuspender()"
          v-if="
            haveRoles(['ROLE_UACI', 'ROLE_TITULAR_INSTITUCION']) &&
            proceso.id_estado_proceso === 1 &&
            [2, 3].includes(proceso.id_seguimiento_proceso)
          "
          color="amber lighten-3"
          ><v-icon class="mx-1">mdi-pause-circle</v-icon>Suspender
          proceso</v-btn
        >
        <v-btn
          class="my-2 ml-4"
          v-if="proceso.id_estado_proceso === 6"
          color="secondary"
          dark
          @click="modalReanudarProc()"
          block
        >
          <v-icon class="mx-1">mdi-play-circle</v-icon>Reanudar proceso
        </v-btn>
        <v-btn
          @click="cambioEstadoProceso(11)"
          color="secondary white--text"
          block
          :disabled="!(proceso.id_empleado_tecnico > 0)"
          v-if="
            haveRoles([
              'ROLE_UACI',
              'ROLE_UACI_TECNICO',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ]) && proceso.id_seguimiento_proceso == 1
          "
        >
          Recepción de solicitudes
        </v-btn>
        <v-btn
          class="my-2 ml-4"
          @click="abrirModalDesierto()"
          v-if="
            haveRoles([
              'ROLE_UACI',
              'ROLE_DIRECCION_COMPRAS',
              'ROLE_TECNICO_DIRECCION_COMPRAS',
            ]) &&
            proceso.id_seguimiento_proceso == 3 &&
            proceso.id_estado_proceso === 1
          "
          color="error white--text"
          ><v-icon color="white" class="mx-1">mdi-account-cancel</v-icon
          >Declarar desierto</v-btn
        >
        <p
          class="text-subtitle-1 text-center mb-0"
          v-if="!(proceso.id_empleado_tecnico > 0)"
        >
          Debe completar los datos generales del proceso
        </p>
      </v-col>
    </v-row>

    <p class="text-h5 secondary--text my-4">{{ proceso?.nombre_proceso }}</p>

    <v-btn
      v-if="
        this.haveRoles([
          'ROLE_DIRECCION_COMPRAS',
          'ROLE_TECNICO_DIRECCION_COMPRAS',
        ]) &&
        proceso.id_estado_proceso === 1 &&
        [1, 11].includes(proceso.id_seguimiento_proceso)
      "
      color="warning"
      class="mb-4 white--text"
      @click="$refs.modalCancelarProceso.show = true"
    >
      <v-icon color="white">mdi-delete</v-icon> Cancelar proceso
    </v-btn>

    <v-tabs
      v-model="tab_selected"
      color="secondary"
      show-arrows
      background-color="bgMinsal"
    >
      <v-tab
        v-for="(item, index) in OPTIONS_TAB"
        :key="index"
        class="text-no-style secondary--text"
      >
        <v-icon color="secondary"> {{ item.icon }} c</v-icon>
        <span class="ml-2" v-text="item.text" />
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab_selected" class="my-7">
      <v-tab-item>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row class="mt-1">
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Código interno del proceso"
                  v-model="proceso.codigo_interno"
                  :error-messages="codigoProcesoError"
                  @input="$v.proceso.codigo_interno.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  label="Nombre del proceso"
                  v-model="proceso.nombre_proceso"
                  @input="$v.proceso.nombre_proceso.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <money-text-field-component
                  ref="monto"
                  label="Suma de mercancía *"
                  v-model="proceso.suma_mercancia"
                  readonly
                  outlined
                  :error-messages="montoErrors"
                  :disabled="
                    proceso.numero_cdp !== '' && proceso.numero_cdp !== null
                  "
                  @input="$v.proceso.suma_mercancia.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="proceso.id_forma_contratacion"
                  :items="modalidades"
                  item-text="nombre"
                  item-value="id"
                  label="Modalidad de compra *"
                  outlined
                  disabled
                  @blur="$v.proceso.id_forma_contratacion.$touch()"
                  @input="$v.proceso.id_forma_contratacion.$touch()"
                  :error-messages="modalidadError"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="tecnicos_list"
                  item-text="nombre_completo_tecnico"
                  item-value="id_empleado"
                  outlined
                  label="Técnico asignado *"
                  v-model="proceso.id_empleado_tecnico"
                  @blur="$v.proceso.id_empleado_tecnico.$touch()"
                  :error-messages="tecnicoError"
                  @change="guardarDatosProceso"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0">Inicio del proceso</p>
                <v-date-picker
                  v-model="picker"
                  type="month"
                  locale="es-Es"
                  header-color="#404a7a"
                  style="margin-right: 40px; margin-top: 15px"
                  :min="moment().format('YYYY-MM-DD')"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0">Finalización del proceso</p>
                <v-date-picker
                  :min="picker"
                  :disabled="picker == ''"
                  v-model="picker2"
                  type="month"
                  locale="es-Es"
                  style="margin-top: 15px; color-text: white"
                  header-color="#404a7a"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])"
          >
            <v-row class="my-2" v-if="documentos.length === 0">
              <v-col cols="12" sm="12" md="10">
                <v-alert border="left" type="info" elevation="2" colored-border
                  >Este documento solo se puede subir una vez</v-alert
                >
              </v-col>
            </v-row>
            <v-row v-if="documentos.length === 0">
              <v-col cols="12" sm="9" md="8">
                <v-file-input
                  label="Expediente de proceso"
                  outlined
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  accept="application/pdf"
                  v-model="expediente"
                  :error-messages="expedienteError"
                  @blur="$v.expediente.$touch()"
                  @input="$v.expediente.$touch()"
                ></v-file-input>
              </v-col>
              <v-col cols="12" sm="3" md="4">
                <v-btn color="secondary" @click="cargar()">Cargar</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="documentos.length > 0">
              <v-col cols="12" sm="12" md="3">
                <v-btn text color="secondary" @click="descargarArchivo">
                  <v-icon color="secondary">mdi-download</v-icon>
                  Descargar expediente de proceso
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" sm="4" md="2">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
              >Volver</v-btn
            >
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-btn block color="secondary" @click="guardarDatosProceso"
              >Guardar</v-btn
            >
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <PipProcesoComponent
          ref="pipComponent"
          :action="getProceso"
          :etapas="proceso?.EtapaPorProcesos"
        />

        <v-row class="mt-8">
          <v-col cols="12" sm="2">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
              >Volver</v-btn
            >
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <PEOComponent />
        <v-row class="mt-8">
          <v-col cols="12" sm="2">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
              >Volver</v-btn
            >
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <doc-proveedor-component :proceso="proceso" />
        <v-row class="mt-8">
          <v-col cols="12" sm="2">
            <v-btn
              block
              outlined
              color="secondary"
              :to="{ name: 'pac-procesos' }"
            >
              Volver
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog
      v-model="modalDesierto"
      max-width="60vh"
      @click:outside="cerrar()"
    >
      <v-card>
        <section>
          <v-card-title class="text-h5 secondary--text"
            >Declarar proceso desierto</v-card-title
          >
          <v-card-text>
            <p class="text-h6">
              ¿Desea declarar el siguiente proceso como desierto?
            </p>
            <h4>Proceso</h4>
            <h5>{{ proceso.codigo_proceso }}</h5>
            <h5>{{ proceso.nombre_proceso }}</h5>
            <h5>{{ proceso.FormaContratacion?.nombre }}</h5>
            <h5>
              $
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(proceso.suma_mercancia)
              }}
            </h5>
            <v-row class="mt-2">
              <v-col cols="11">
                <v-file-input
                  label="Justificación"
                  outlined
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  append-icon=""
                  accept="application/pdf"
                  v-model="documento"
                  :error-messages="documentoError"
                  @blur="$v.documento.$touch()"
                  @input="$v.documento.$touch()"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row class="mx-1">
              <v-btn outlined color="secondary" class="mr-4" @click="cerrar">
                Volver
              </v-btn>
              <v-btn color="secondary" class="mr-4" @click="declararDesierto()">
                Declarar desierto
              </v-btn>
            </v-row>
          </v-card-text>
        </section>
      </v-card>
    </v-dialog>    

    <ConfirmationDialogComponent
      :show="showConfirmation"
      title="¿Está seguro de publicar este proceso?"
      message="De confirmarse la siguiente acción los cambios no serán reversibles"
      btnConfirmar="Confirmar"
      @close="showConfirmation = false"
      @confirm="publish"
    />

    <!-- Modal para confirmar cancelación de proceso -->
    <ModalCancelarProcesoComponent
      ref="modalCancelarProceso"
      @hideModal="hideModalCancelarProceso"
      @success="getProceso"
    />

    <ProcesoSuspenderComponent
      :modal="modalProceso"
      :proceso="proceso"
      @cerrar="cerrarModal"
    />

    <ReanudarProcesoComponent
      :modalRea="modalReanudar"
      @cerrarR="cerrarRea()"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import { mapMutations } from "vuex";
import { required, helpers } from "vuelidate/lib/validators";
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";
import PipProcesoComponent from "./components/PipProcesoComponent.vue";
import PEOComponent from "./components/ConfiguracionPEOComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import DocProveedorComponent from "./components/DocProveedorComponent.vue";
import ModalCancelarProcesoComponent from "../ProcesoCompraDoc/components/ModalCancelarProcesoComponent.vue";
import ProcesoSuspenderComponent from "./components/ProcesoSuspenderComponent.vue";
import ReanudarProcesoComponent from "./components/ReanudarProcesoComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/);
const priceDecimals = helpers.regex(
  "priceDecimals",
  /^[0-9]+([.][0-9]{1,2})?$/
);

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "ConfiguracionProcesoView",
  components: {
    MoneyTextFieldComponent,
    PipProcesoComponent,
    PEOComponent,
    ConfirmationDialogComponent,
    DocProveedorComponent,
    ModalCancelarProcesoComponent,
    ProcesoSuspenderComponent,
    ReanudarProcesoComponent,
  },
  data: () => ({
    tab_selected: null,
    proceso: {
      codigo_interno: "",
      nombre_proceso: "",
      id_forma_contratacion: null,
      suma_mercancia: 0,
      id_empleado_tecnico: null,
    },
    modalidades: [],
    picker: moment().format("YYYY-MM"),
    picker2: null,
    tecnicos_list: [],
    showConfirmation: false,
    modalProceso: false,
    modalReanudar: false,
    documentos: [],
    documento_cargado: null,
    expediente: null,
    
    modalDesierto: false,
    documento: null,
  }),
  validations: {
    proceso: {
      codigo_interno: { required, letter },
      nombre_proceso: { required, letter },
      id_forma_contratacion: { required },
      suma_mercancia: { required, priceDecimals },
      id_empleado_tecnico: { required },
    },
    documento: {
      required,
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
    expediente: { 
      fileTypeValid, 
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    OPTIONS_TAB() {
      if (this.proceso.id_seguimiento_proceso !== 1) {
        return [
          {
            icon: "mdi-file-cog",
            text: "General",
          },
          {
            icon: "mdi-format-list-bulleted",
            text: "Plan de implementación del proceso",
          },
          {
            icon: "mdi-card-account-details-outline",
            text: "PEO",
          },
          {
            icon: "mdi-file-document-multiple",
            text: "Documentos para proveedores",
          },
        ];
      } else {
        return [
          {
            icon: "mdi-file-cog",
            text: "General",
          },
        ];
      }
    },
    breads() {
      return [
        {
          text: "Procesos de compra",
          disabled: false,
          href: { name: "pac-procesos" },
        },
        {
          text: `${this.proceso?.codigo_proceso} / ${this.proceso?.codigo_interno}`,
          disabled: false,
          href: { name: "pac-procesos" },
        },
      ];
    },
    disableBtn() {
      return (
        this.proceso?.id_empleado_tecnico == null ||
        this.proceso?.EtapaPorProcesos?.filter(
          (target) =>
            target.fecha_hora_inicio == null || target.fecha_hora_fin == null
        ).length > 0
      );
    },
    codigoProcesoError() {
      const errors = [];
      if (!this.$v.proceso.codigo_interno.$dirty) return errors;
      !this.$v.proceso.codigo_interno.required &&
        errors.push("El código interno del proceso es requerido");
      !this.$v.proceso.codigo_interno.letter &&
        errors.push(
          "El código interno del proceso no puede contener caracteres especiales"
        );
      return errors;
    },
    nombreProcesoError() {
      const errors = [];
      if (!this.$v.proceso.nombre_proceso.$dirty) return errors;
      !this.$v.proceso.nombre_proceso.required &&
        errors.push("El nombre del proceso es requerido");
      !this.$v.proceso.nombre_proceso.letter &&
        errors.push(
          "El nombre del proceso no puede contener caracteres especiales"
        );
      return errors;
    },
    montoErrors() {
      const errors = [];
      if (!this.$v.proceso.suma_mercancia.$dirty) return errors;
      !this.$v.proceso.suma_mercancia.required &&
        errors.push("El campo monto es obligatorio");
      !this.$v.proceso.suma_mercancia.priceDecimals &&
        errors.push(
          "El campo solo admite enteros o decimales y 2 dígitos después del punto decimal"
        );
      return errors;
    },
    modalidadError() {
      const errors = [];
      if (!this.$v.proceso.id_forma_contratacion.$dirty) return errors;
      !this.$v.proceso.id_forma_contratacion.required &&
        errors.push("La modalidad de compra es requerida");
      return errors;
    },
    tecnicoError() {
      const errors = [];
      if (!this.$v.proceso.id_empleado_tecnico.$dirty) return errors;
      !this.$v.proceso.id_empleado_tecnico.required &&
        errors.push("Técnico asignado es requerido");
      return errors;
    },
    expedienteError() {
      const errors = [];
      if (!this.$v.expediente.$dirty) return errors;
      !this.$v.expediente.fileTypeValid &&
        errors.push("Solo se permiten archivos PDF");
      !this.$v.expediente.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      !this.$v.expediente.fileNameValid &&
        errors.push(
          "El nombre del archivo no debe contener caracteres especiales"
        );
      return errors;
    },
    documentoError() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe de subir un documento");
      !this.$v.documento.fileTypeValid &&
        errors.push("Solo se permiten archivos PDF");
      !this.$v.documento.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      !this.$v.documento.fileNameValid &&
        errors.push(
          "El nombre del archivo no debe contener caracteres especiales"
        );
      return errors;
    },
  },
  methods: {
    ...mapMutations(["setTitle"]),
    abrirModalSuspender() {
      this.modalProceso = true;
    },
    cerrarModal() {
      this.modalProceso = false;
      this.getProceso();
    },
    modalReanudarProc() {
      this.modalReanudar = true;
    },
    cerrarRea() {
      this.modalReanudar = false;
      this.getProceso();
    },
    async getProceso() {

      const response = await this.services.PacProcesos.getProceso(
        this.$route.params.idProceso
      ).catch(() => {

      });

      if (response.status === 200) {
        if (response.data.version != this.$route.meta.version) {
          this.$router.push({
            name: "dashboard",
          });

          this.temporalAlert({
            message:
              "Hay inconsistencias en la versión del proceso, si el problema persiste contacte al administrador",
            show: true,
            type: "warning",
          });
        }

        this.proceso = response.data;
        this.proceso.suma_mercancia = Number(response.data.suma_mercancia);
        this.$refs.monto.updateValue(this.proceso.suma_mercancia);
        this.picker = moment(response.data.fecha_inicio_proceso).format(
          "YYYY-MM"
        );
        this.picker2 = moment(response.data.fecha_contratacion).format(
          "YYYY-MM"
        );
        this.proceso.id_empleado_tecnico = response.data.id_empleado_tecnico;
        this.proceso.id_seguimiento_proceso =
          response.data.id_seguimiento_proceso;

        // Documentos proceso
        let response2 =
          await this.services.PacProcesos.obtenerDocumentosProceso(
            this.$route.params.idProceso,
            5000
          ).catch(() => {

          });
        if (response2?.status === 200) {
          this.documentos = response2?.data;
          this.documento_cargado = this.documentos[0]?.ruta_documento;
        }
        //
      }

    },
    async fetchModalidades() {
      const response = await this.services.Paac.getModalidades({
        compra_tradicional: true,
      });
      if (response) this.modalidades = response.data.forma_contratacion;
    },
    async guardarDatosProceso() {

      let params = {
        codigo_interno: this.proceso.codigo_interno,
        nombre_proceso: this.proceso.nombre_proceso,
        suma_mercancia: Number(this.proceso.suma_mercancia),
        fecha_inicio: this.moment(this.picker)
          .startOf("month")
          .format("YYYY-MM-DD hh:mm"),
        fecha_fin: this.moment(this.picker2)
          .endOf("month")
          .format("YYYY-MM-DD hh:mm"),
        id_empleado_tecnico: this.proceso.id_empleado_tecnico,
      };

      const { status } = await this.services.PacProcesos.actualizarProceso(
        this.$route.params.idProceso,
        params
      ).catch(() => {

      });
      if (status === 200 || status === 201) {
        setTimeout(() => {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Proceso de compra actualizado",
          });

        }, 1500);
      }
    },
    async getTecnicos() {
      const { status, data } =
        await this.services.Paac.getTecnicosProcesoCompra(
          this.$route.params.idProceso
        );

      if (status == 200) {
        this.tecnicos_list = data;
      }
    },
    async publish() {

      const { status } = await this.services.PacProcesosV1.publicarProceso(
        this.$route.params.idProceso
      ).catch(() => {

      });

      if (status == 200) {
        setTimeout(() => {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Proceso de compra publicado con éxito",
          });

        }, 1500);

        location.reload();
      }
    },
    async cambioEstadoProceso(id_siguiente_estado) {


      const { status } =
        await this.services.PacProcesos.cambioEstadoProcesoCompra(
          this.$route.params.idProceso,
          id_siguiente_estado
        )

      if (status == 200) {
        setTimeout(() => {

        }, 1500);

        location.reload();
      }
    },

    hideModalCancelarProceso() {
      this.$refs.modalCancelarProceso.show = false;
    },

    async cargar() {
      this.$v.$touch();

      const formData = new FormData();
      formData.append("documento", this.expediente);
      formData.append("nombre", "Expediente de proceso");
      formData.append("id_proceso", this.$route.params.idProceso);
      formData.append("id_tipo_documento", 5000);
      formData.append("folder", "expediente_proceso");
      const response = await this.services.PacProcesos.guardarDocumentosProceso(
        formData
      ).catch(() => {
        if (this.$v.$invalid) {
          if (this.expediente === null) {
            this.temporalAlert({
              show: true,
              type: "error",
              message: "Debe de subir un documento",
            });
          }

        }
      });
      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Documento cargado exitosamente",
        });
        this.getProceso();
        this.expediente = null;
        this.$v.$reset();
      }


    },
    async descargarArchivo() {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: this.documento_cargado,
        })
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], this.documentos[0].nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
    abrirModalDesierto() {
      this.modalDesierto = true;
    },
    cerrar() {
      this.modalDesierto = false;
      this.documento = null;
    },
    async declararDesierto() {
      if (this.documento) {

        let formData = new FormData();
        formData.append("documento", this.documento);
        const response = await this.services.PacProcesos.putProcesoDesierto(
          this.$route.params.idProceso,
          formData
        )

        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          this.cerrar();

          this.temporalAlert({
            show: true,
            type: "success",
            message: "Proceso declarado desierto",
          });
          setTimeout(() => {
            window.close();
          }, 1000);
        }
      } else {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "Debe cargar un documento",
        });
      }
    },
  },
  watch: {
    picker() {
      if (!this.$route.params.idProceso) this.picker2 = this.picker;
    },
  },
  async created() {
    await this.getProceso();
    this.setTitle(
      `COMPRASAL | ${this.proceso?.codigo_proceso} - ${this.proceso?.nombre_proceso}`
    );
    this.fetchModalidades();
    this.getTecnicos();
  },
  beforeDestroy() {
    this.setTitle("COMPRASAL");
  },
};
</script>
